import React, { useEffect, useContext, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Logout from "../login/Logout";
import ManagementNav from "./ManagementNav";
import RemainderCard from "../general/RemainderCard";
import { UserContext } from "../UserContextManager";
import { ManagementContext } from "../ManagementHelpersManager";
import UserInfo from "../general/UserInfo";
import axios from "axios";
import { DeskChangeBtn } from "../general/DeskChangeBtn";
import { ManagementSecretContext } from "../ManagementSecretContext";

function ManagementDesk() {
  const uContext = useContext(UserContext);
  const mContext = useContext(ManagementContext);
  const msContext = useContext(ManagementSecretContext);

  const navigate = useNavigate();

  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);

  const loadUserData = async () => {
    let userDataRes = await axios.get("/redesk/general/remainder");

    let data = await userDataRes.data;

    if (['ЮЛК', 'БМЗ'].includes(data.user_data.abbreviation.replace(/(\r\n|\n|\r)/gm, ""))) {
      let secretHelpers = await axios.get("/redesk/management/secrethelpers");
      msContext.setHelpersData(secretHelpers.data.helpers_data);
    }

    let draftRes = await axios.get("/redesk/draft");

    let draftData = await draftRes.data;

    data.user_data["draft_data"] = draftData.draft_data;

    uContext.setUserData(data.user_data);

    let roles = data.user_data.role.split(";");
    if (roles.includes("management")) {
      navigate("/management");
    } else {
      navigate("/");
    }
  };

  const loadManagementHelpers = async (emonth = month, eyear = year) => {
    let res = await axios.get("/redesk/management/helpers");
    mContext.setHelpersData(res.data.helpers_data);
  };

  useEffect(() => {
    loadUserData();
    loadManagementHelpers();

    return () => {};
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid>
      <Row className="justify-content-center" style={{ marginTop: "15px" }}>
        <Col xs={6} style={{ textAlign: "left" }}>
          <UserInfo />
        </Col>
        <Col xs={6} style={{ textAlign: "right" }}>
          {uContext.userData.role.split(";").length > 1 ? (
            <DeskChangeBtn />
          ) : (
            <></>
          )}
          <Logout />
        </Col>
      </Row>
      <Row
        className="justify-content-center mb-5"
        style={{ marginTop: "100px" }}
      >
        <Col sm={12} lg={2}>
          <RemainderCard
            bgColor="primary"
            keyProp="in_desk"
            textColor="white"
            title="Факт в кассе"
            value={mContext.helpersData.in_desk.toFixed(2)}
            tooltipText="Остаток + сумма исходящих наличных неподтвержденных перебросок"
            tooltipPlacement="right"
          />
          <RemainderCard
            bgColor="light"
            keyProp="cash"
            textColor="black"
            title="Нал"
            value={mContext.helpersData.cash.toFixed(2)}
            tooltipText="Сумма наличных"
          />
          <RemainderCard
            bgColor="light"
            keyProp="noncash"
            textColor="black"
            title="Карта"
            value={mContext.helpersData.noncash.toFixed(2)}
            tooltipText="Сумма по карте"
          />
          <RemainderCard
            bgColor="light"
            keyProp="remainder"
            textColor="black"
            title="Остаток"
            value={mContext.helpersData.remainder.toFixed(2)}
            tooltipText="Нал + Карта + ФОТ Сбербанк"
            tooltipPlacement="right"
          />
          <RemainderCard
            bgColor="light"
            keyProp="fot_sber"
            textColor="black"
            title="ФОТ Сбербанк"
            value={mContext.helpersData.fot_sber.toFixed(2)}
            tooltipText="ФОТ Сбербанк"
            tooltipPlacement="right"
          />
        </Col>
        <Col sm={12} lg={8} className="p-0 mb-5">
          <ManagementNav />
        </Col>
        <Col sm={12} lg={2}>
          <RemainderCard
            bgColor="light"
            keyProp="outcoming_reas_remainder"
            textColor="black"
            title="Исходящие переброски"
            value={mContext.helpersData.outcoming.toFixed(2)}
            tooltipText="Сумма всех исходящих наличных неподтвержденных перебросок"
            tooltipPlacement="left"
          />
          <RemainderCard
            bgColor="light"
            keyProp="incoming_reas_remainder"
            textColor="black"
            title="Входящие переброски"
            value={mContext.helpersData.incoming.toFixed(2)}
            tooltipText="Сумма всех входящих наличных неподтвержденных перебросок"
            tooltipPlacement="left"
          />

          {["ЮЛК", "БМЗ"].includes(uContext.userData.abbreviation) ? (
            <>
              <RemainderCard
                bgColor="warning"
                keyProp="incoming_reas_remainder"
                textColor="black"
                title="Сумма нал"
                value={(msContext.helpersData.secret_cash + mContext.helpersData.incoming).toFixed(2)}
                tooltipText="Подтвержденный нал"
                tooltipPlacement="left"
              />
              <RemainderCard
                bgColor="warning"
                keyProp="incoming_reas_remainder"
                textColor="black"
                title="Сумма по выпискам (безнал)"
                value={msContext.helpersData.secret_noncash.toFixed(2)}
                tooltipText="Сумма выписок"
                tooltipPlacement="left"
              />
            </>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default ManagementDesk;
