import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Draft from "../general/Draft";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import ManagementSpbMainTerminal from "./ManagementSpbMainTerminal";
import SpbAmegaTerminal from "./ManagementSpbAmegaTerminal"
import FotStatement from "../general/FotStatement";

function ManagementSpbTerminal() {
  const [tabState, setTabState] = useState("terminal-main");

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <Container fluid className=" shadow pt-2">
      <Row>
        <Col>
          <Tabs
            justify
            id="controlled-inner-tab"
            activeKey={tabState}
            onSelect={(k) => setTabState(k)}
            unmountOnExit={false}
            style={{ maxWidth: "450px" }}
          >
            {" "}
            <Tab eventKey="terminal-main" title="Основной">
              <ManagementSpbMainTerminal />
            </Tab>
            <Tab eventKey="terminal-amega" title="Амега">
              <SpbAmegaTerminal />
            </Tab>
            <Tab eventKey="terminal-statement" title="Ведомость ФОТ">
              <FotStatement />
            </Tab>
          </Tabs>
        </Col>
        <Col xs={12}>
          <Draft />
        </Col>
      </Row>
    </Container>
  );
}

export default ManagementSpbTerminal;
