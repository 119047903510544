import React, { useState, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Typeahead, Highlighter } from "react-bootstrap-typeahead";
import { Row, Col, Button, Stack, Form } from "react-bootstrap";
import { UserContext } from "../UserContextManager";
import { NUContext } from "../NUHelpersManager";
import axios from "axios";
import cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import CurrencyInput from "react-currency-input-field";

function FotStatement() {
  const employeeRef = React.createRef();

  const uContext = useContext(UserContext);

  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);

  const [cfo, setCfo] = useState("ЦФО");

  const [dropdownData, setDropdownData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [comment, setComment] = useState("");

  const [item, setItem] = useState(window.location.pathname == '/management' ? "ФОТ" : "ФОТ прорабы");

  const [sum, setSum] = useState("");

  const [formData, updateFormData] = useState({});

  const [paymentType, setPaymentType] = useState("Нал");

  const userRoles = uContext.userData.role.split(";");

  const loadDropdownData = async () => {
    let res = await axios.get(
      `/redesk/general/fot-statement-dropdowns/${window.location.pathname}`
    );

    let cfoList = res.data.dropdowns.filter(function (x) {
      return x.dropdown_name === "cfo";
    });

    setDropdownData(res.data.dropdowns);

    if (cfoList.length === 1) {
      setCfo(cfoList[0].label);
      loadEmployee(cfoList[0].label);

      let fdCopy = { ...formData };
      fdCopy["terminal_fs_cfo"] = cfoList[0].label;
      updateFormData(fdCopy);
    }
  };

  const loadEmployee = async (cfo) => {
    let res = await axios.get(`/redesk/general/fot-statement-employee/${cfo}`);

    setEmployeeData(res.data.employee_list);
  };

  const handleChange = (e, k = null) => {
    if (k !== null) {
      updateFormData({ ...formData, [k]: e });
    } else {
      updateFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.terminal_fs_cfo) {
      alert("Выберите ЦФО!");
      return;
    }

    if (!sum || sum == "" || sum == 0) {
      alert("Введите сумму!");
      return;
    }

    if (
      !formData.terminal_fs_employee ||
      !employeeData.includes(formData.terminal_fs_employee)
    ) {
      alert("Сотрудник указан неправильно!");
      return;
    }

    let draftSendData = formData;
    draftSendData["terminal_fs_month"] = month;
    draftSendData["terminal_fs_item"] = item;
    draftSendData["terminal_fs_year"] = year;
    draftSendData["current_desk_owner"] = window.location.pathname;
    draftSendData["terminal_fs_sum"] = sum;
    draftSendData["terminal_fs_payment_type"] = paymentType;
    draftSendData["terminal_fs_comment"] = comment;

    if (
      draftSendData["terminal_fs_account"] &&
      draftSendData["terminal_fs_account"] === "Счет"
    ) {
      delete draftSendData["terminal_fs_account"];
    }

    employeeRef.current.clear();

    let res = await axios.post(
      "/redesk/draft/fot-statement/send",
      draftSendData,
      { headers: { "X-CSRF-TOKEN": cookies.get("csrf_access_token") } }
    );

    let contextData = uContext.userData;
    contextData.draft_data = res.data.draft_data;
    uContext.setUserData(contextData);

    setSum("");
    setComment("");

    let fdCopy = { ...formData };
    delete fdCopy["terminal_fs_employee"];
    updateFormData(fdCopy);

    /*document.getElementById("terminal_fs_form").reset();
    setYear(new Date().getFullYear());
    setMonth(new Date().getMonth());
    setCfo('ЦФО')*/
  };

  useEffect(() => {
    loadDropdownData();
    //loadEmployee();
    return () => {};
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Form id="terminal_fs_form" className="mt-2">
        <Row>
          <Col xs={6}>
            <Form.Group className="mb-3" controlId="t_fs_cfo">
              <Form.Label>ЦФО</Form.Label>
              <Form.Select
                size="sm"
                name="terminal_fs_cfo"
                value={cfo}
                aria-label="terminal_fs_cfo"
                onChange={(e) => {
                  setCfo(e.target.value);
                  handleChange(e);
                  loadEmployee(e.target.value);
                }}
              >
                <option key="ЦФО" value="ЦФО" disabled={true}>
                  ЦФО
                </option>
                {dropdownData.map(({ value, label, dropdown_name }, index) => {
                  if (dropdown_name === "cfo") {
                    return (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    );
                  }
                  return null;
                })}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={3}>
            <Form.Group className="mb-3" controlId="t_fs_m">
              <Form.Label>Месяц начисления</Form.Label>
              <Form.Select
                size="sm"
                name="terminal_fs_month"
                value={month}
                aria-label="terminal_fs_month"
                onChange={(e) => {
                  setMonth(e.target.value);
                }}
              >
                {[...Array(12)].map((e, i) => (
                  <option key={i + 1} value={i + 1}>
                    {i + 1}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={3}>
            <Form.Group className="mb-3" controlId="t_fs_y">
              <Form.Label>Год начисления</Form.Label>
              <Form.Select
                size="sm"
                name="terminal_fs_year"
                value={year}
                aria-label="terminal_fs_year"
                onChange={(e) => {
                  setYear(e.target.value);
                }}
              >
                {" "}
                <option key="2025" value="2025">
                  2025
                </option>
                <option key="2024" value="2024">
                  2024
                </option>
                <option key="2023" value="2023">
                  2023
                </option>
                <option key="2022" value="2022">
                  2022
                </option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          {window.location.pathname === "/nu" ? (
            <>
              <Col xs={3}>
                <Form.Group className="mb-3" controlId="t_fs_s">
                  <Form.Label>Сумма</Form.Label>
                  <CurrencyInput
                    autoComplete="off"
                    id="terminal_fs_sum"
                    name="terminal_fs_sum"
                    className="form-control"
                    style={{ maxHeight: "30px" }}
                    value={sum}
                    placeholder="Сумма"
                    decimalsLimit={2}
                    allowNegativeValue={false}
                    groupSeparator={" "}
                    onValueChange={(e) => {
                      setSum(e);
                      handleChange(e, "terminal_fs_sum");
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Group className="mb-3" controlId="t_fs_i">
                  <Form.Label>Статья</Form.Label>
                  <Form.Select
                    size="sm"
                    name="terminal_fs_item"
                    value={item}
                    aria-label="terminal_fs_item"
                    onChange={(e) => setItem(e.target.value)}
                  >
                    <option key="ФОТ прорабы" value="ФОТ прорабы">
                      ФОТ прорабы
                    </option>
                    <option
                      key="ФОТ помощники прорабов"
                      value="ФОТ помощники прорабов"
                    >
                      ФОТ помощники прорабов
                    </option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </>
          ) : (
            <Col xs={6}>
              <Form.Group className="mb-3" controlId="t_fs_s">
                <Form.Label>Сумма</Form.Label>
                <CurrencyInput
                  autoComplete="off"
                  id="terminal_fs_sum"
                  name="terminal_fs_sum"
                  className="form-control"
                  style={{ maxHeight: "30px" }}
                  value={sum}
                  placeholder="Сумма"
                  decimalsLimit={2}
                  allowNegativeValue={false}
                  groupSeparator={" "}
                  onValueChange={(e) => {
                    setSum(e);
                    handleChange(e, "terminal_fs_sum");
                  }}
                />
              </Form.Group>
            </Col>
          )}
          <Col>
            <Form.Group className="mb-3" controlId="t_r_u">
              <Form.Label>Сотрудник</Form.Label>
              <Typeahead
                key="terminal_fs_employee"
                id="terminal_fs_employee"
                name="terminal_fs_employee"
                ref={employeeRef}
                labelKey={"value"}
                placeholder="Сотрудник"
                maxResults={7}
                emptyLabel="Нет совпадений"
                paginationText="Показать больше"
                highlightClassName="font-weight-bold"
                onChange={(selected) =>
                  handleChange(selected[0], "terminal_fs_employee")
                }
                onInputChange={(text) =>
                  handleChange(text, "terminal_fs_employee")
                }
                options={employeeData}
                size="sm"
                renderMenuItemChildren={(option, props, idx) => (
                  <Highlighter
                    search={props.text}
                    highlightClassName="fw-bold mx-0 px-0 bg-transparent"
                  >
                    {option}
                  </Highlighter>
                )}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Form.Group className="mb-3" controlId="t_fs_c">
              <Form.Label>Примечание</Form.Label>
              <Form.Control
                size="sm"
                name="terminal_fs_comment"
                value={comment}
                autoComplete="off"
                placeholder="Примечание"
                type="text"
                onInput={(e) => setComment(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group className="mb-3" controlId="t_f_p_t">
              <Form.Label>Тип платежа</Form.Label>
              <Form.Select
                size="sm"
                name="terminal_fs_payment_type"
                aria-label="terminal_fs_payment_type"
                value={paymentType}
                onChange={(e) => {
                  setPaymentType(e.target.value);
                }}
              >
                <option key="Нал" value="Нал">
                  Нал
                </option>
                <option key="Безнал" value="Безнал">
                  Безнал
                </option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={6}>
            {window.location.pathname === "/management" ? (
              <Form.Group className="mb-3" controlId="t_fs_a">
                <Form.Label>Счет</Form.Label>
                <Form.Select
                  size="sm"
                  name="terminal_fs_account"
                  defaultValue="Счет"
                  aria-label="terminal_fs_account"
                  onChange={handleChange}
                >
                  <option key="Счет" value="Счет">
                    Счет
                  </option>
                  {dropdownData.map(
                    ({ value, label, dropdown_name }, index) => {
                      if (dropdown_name === "account") {
                        return (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        );
                      }
                      return null;
                    }
                  )}
                </Form.Select>
              </Form.Group>
            ) : (
              <div></div>
            )}
          </Col>
          {window.location.pathname === "/management" ? (
            <Col xs={6}>
              <Form.Group className="mb-3" controlId="t_fs_i">
                <Form.Label>Статья</Form.Label>
                <Form.Select
                  size="sm"
                  name="terminal_fs_item"
                  value={item}
                  aria-label="terminal_fs_item"
                  onChange={(e) => setItem(e.target.value)}
                >
                  <option key="ФОТ" value="ФОТ">
                    ФОТ
                  </option>
                  <option key="ФОТ Налог" value="ФОТ Налог">
                    ФОТ Налог
                  </option>
                  <option key="ФОТ прорабы" value="ФОТ прорабы">
                    ФОТ прорабы
                  </option>
                  <option
                    key="ФОТ помощники прорабов"
                    value="ФОТ помощники прорабов"
                  >
                    ФОТ помощники прорабов
                  </option>
                </Form.Select>
              </Form.Group>
            </Col>
          ) : (
            <></>
          )}
        </Row>
      </Form>
      <Row className="mb-2">
        <Stack gap={2}>
          <Button
            variant="primary"
            className="mb-3 mt-2 mx-auto"
            onClick={handleSubmit}
          >
            Отправить
          </Button>
        </Stack>
      </Row>
    </>
  );
}

export default FotStatement;
